<template>
  <div class="m-card">
    <div class="row no-gutters p-3">
      <div class="col-xl-12">
        <h1>
          <strong>{{ $t("registrationEmail.title") }}</strong>
        </h1>
        <p>{{ $t("registrationEmail.message") }}</p>
        <div class="m-card__text">
          <p class="border-bottom m-card__text--small">
            <strong>
              {{ $t("registrationEmail.subtitle_0") }}
            </strong>
          </p>

          <div class="m-card--info">
            <p>{{ $t("registration.name") }}:</p>
            <p>
              <strong>{{ userData.AnagraficaUtente.nome }}</strong>
            </p>
          </div>
          <div class="m-card--info">
            <p>{{ $t("registration.surname") }}:</p>
            <p>
              <strong>{{ userData.AnagraficaUtente.cognome }}</strong>
            </p>
          </div>
          <div class="m-card--info" v-if="userData.AnagraficaUtente.codiceFiscale != null">
            <p>{{ $t("registration.cf") }}:</p>
            <p>
              <strong>{{ userData.AnagraficaUtente.codiceFiscale }}</strong>
            </p>
          </div>
          <div class="m-card--info" v-if="userData.AnagraficaUtente.codiceSTP != null">
            <p>{{ $t("registration.stp") }}:</p>
            <p>
              <strong>{{ userData.AnagraficaUtente.codiceSTP }}</strong>
            </p>
          </div>
          <div class="m-card--info" v-if="userData.AnagraficaUtente.codiceTeams != null">
            <p>{{ $t("registration.team") }}:</p>
            <p>
              <strong>{{ userData.AnagraficaUtente.codiceTeams }}</strong>
            </p>
          </div>
          <div class="m-card--info">
            <p>{{ $t("registration.birthdate") }}:</p>
            <p>
              <strong>{{ stringToDate(userData.AnagraficaUtente.dataNascita) | moment("DD/MM/YYYY") }}</strong>
            </p>
          </div>
          <div class="m-card--info">
            <p>{{ $t("registration.gender") }}:</p>
            <p>
              <strong>{{ userData.AnagraficaUtente.sesso }}</strong>
            </p>
          </div>
          <div class="m-card--info" v-if="userData.AnagraficaUtente.nazionalita != null">
            <p>{{ $t("registration.nation_born") }}:</p>
            <p>
              <strong>{{ userData.AnagraficaUtente.nazionalita.denominazione }}</strong>
            </p>
          </div>
          <div class="m-card--info" v-if="userData.AnagraficaUtente.comuneNascita != null">
            <p>{{ $t("registration.country_born") }}:</p>
            <p>
              <strong>{{ userData.AnagraficaUtente.comuneNascita.provincia }}</strong>
            </p>
          </div>
          <div class="m-card--info" v-if="userData.AnagraficaUtente.comuneNascita != null">
            <p>{{ $t("registration.city_born") }}:</p>
            <p>
              <strong>{{ userData.AnagraficaUtente.comuneNascita.comune }}</strong>
            </p>
          </div>
        </div>
        <div class="m-card__text">
          <p class="border-bottom m-card__text--small">
            <strong>
              {{ $t("registrationEmail.subtitle_1") }}
            </strong>
          </p>
          <div class="m-card--info">
            <p>{{ $t("registration.username") }}:</p>
            <p>
              <strong>
                {{ userData.username }}
              </strong>
            </p>
          </div>
          <div class="m-card--info">
            <p>
              {{ $t("registration.email") }}:
            </p>
            <p>
              <strong>{{ userData.AnagraficaUtente.email }}</strong>
            </p>
          </div>
          <div class="m-card--info">
            <p>
              {{ $t("registration.phone") }}:
            </p>
            <p>
              <strong>{{ userData.AnagraficaUtente.cellulare }}</strong>
            </p>
          </div>
        </div>
        <div class="m-card__text">
          <p class="border-bottom m-card__text--small">
            <strong>
              {{ $t("registrationEmail.subtitle_2") }}
            </strong>
          </p>
          <div class="m-card--info">
            <p>
              {{ $t("registration.nation_billing") }}:
            </p>
            <p v-if="userData.AnagraficaUtente.nazione != null">
              <strong>{{ userData.AnagraficaUtente.nazione.denominazione }}</strong>
            </p>
          </div>
          <div class="m-card--info" v-if="userData.AnagraficaUtente.citta != null">
            <p>
              {{ $t("registration.country_billing") }}:
            </p>
            <p>
              <strong>{{ userData.AnagraficaUtente.citta.provincia }}</strong>
            </p>
          </div>
          <div class="m-card--info" v-if="userData.AnagraficaUtente.citta != null">
            <p>
              {{ $t("registration.city") }}:
            </p>
            <p>
              <strong>{{ userData.AnagraficaUtente.citta.comune }}</strong>
            </p>
          </div>
          <div class="m-card--info">
            <p>
              {{ $t("registration.address") }}:
            </p>
            <p>
              <strong>{{ userData.AnagraficaUtente.indirizzo }}</strong>
            </p>
          </div>
          <div class="m-card--info" v-if="userData.AnagraficaUtente.cap != null">
            <p>
              {{ $t("registration.cap") }}:
            </p>
            <p>
              <strong> {{ userData.AnagraficaUtente.cap }}</strong>
            </p>
          </div>
        </div>
        <div v-if="isApp" class="col-2 offset-3">
          <b-button variant="secondary" class="text-light text-uppercase mt-3 mb-3" @click="closePage">{{
              $t("generic.back")
          }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UTILS from "@/libs/utils.js";
export default {
  name: "AppRegistrationEmail",
  props: ["userData"],

  data() {
    return {
      passwordHide: "*********"
    };
  },
  computed: {
    isApp() {
      return this.$route.query.app
    }
  },

  methods: {
    closePage() {
      UTILS.postMessageToApp({ action: "close" })
    },
    stringToDate(dateString) {
      const date = new Date(dateString);
      return date
    },
    passwordDown() {
      this.passwordHide = this.userData.password;
    },

    passwordUp() {
      this.passwordHide = "*********";
    },
  },
};
</script>
