<template>
  <div class="emailRegistrazione">
    <div class="row no-gutters ">
      <div class="col-10 offset-1">
        <div class="row no-gutters align-items-center o-main">
          <div class="col-lg-6">
            <app-registration-email v-if="service === 'activate'" :userData="registration"></app-registration-email>

            <app-forgot-reactivate-email v-if="service !== 'activate'" :userData="registration" :service="service">
            </app-forgot-reactivate-email>
            <!--       <div class="row no-gutters mt-2">
              <div class="col-lg-6 offset-lg-6 text-right">
                <p>
                  <strong
                    >{{ $t("registrationEmail.email_message")
                    }}<a class="text-success" href="">{{
                      $t("registrationEmail.email_link")
                    }}</a></strong
                  >
                </p>
              </div>
            </div> -->
          </div>
          <div class="col-lg-5 offset-lg-1 d-none d-lg-block">
            <div class="o-text-image">
              <b-img-lazy src="../assets/email-send.svg" alt="email_send"></b-img-lazy>
              <p>
                <strong>{{ $t(message_label + ".email_title") }}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AppRegistrationEmail from "../components/organisms/AppRegistrationEmail.vue";
import AppForgotReactivateEmail from "../components/organisms/AppForgotReactivateEmail";
export default {
  components: { AppRegistrationEmail, AppForgotReactivateEmail },
  name: "emailRegistrazione",
  created() {
    this.getData();
  },
  computed: {
    message_label: function () {
      switch (this.service) {
        case "reactivate":
          return "riattivaUtente";
        case "forgot":
          return "forgot";
        default:
          return "registration";
      }
    }
  },

  data() {
    return {
      service: "activate",
      registration: {
        firstName: "Giacomo",
        lastName: "Magnani",
        codiceFiscale: "mgngcm90c29c618x",
        straniero: false,
        birthDate: "1990-03-29T00:00:00+00:00",
        gender: "M",
        country: "Brescia",
        nationBorn: "Italia",
        cityBornFromTaxCode: "CHIARI",
        username: "mgngcm90c29c618x",
        email: "jack.magnakoki@gmail.com",
        password: "1234567899",
        tel: "3293978528",
        invoice: {
          data: {
            administrative: "Lombardia",
            city: "Palazzolo sull'Oglio",
            country: "Italia",
            countryCode: "it",
            county: "Brescia",
            name: "Via Luigi Pirandello 27",
            postcode: "25036",
            value:
              "Via Luigi Pirandello 27, Palazzolo sull'Oglio, Lombardia, Italia",
          },
        },
      },
    };
  },

  methods: {
    getData() {
      let uri = window.location.href.split('?');
      if (uri.length == 2) {
        let vars = uri[1].split('&');
        let getVars = {};
        let tmp = '';
        vars.forEach(function (v) {
          tmp = v.split('=');
          if (tmp.length == 2)
            getVars[tmp[0]] = tmp[1];
        });

        if ('service' in getVars) {
          this.service = getVars.service;
        }

        if ('data' in getVars) {
          let str = getVars.data;

          if (str.indexOf('%') > 0) {
            str = str.substring(0, str.indexOf('%'));
          }
          const src = atob(str);

          this.registration = JSON.parse(src);

          console.log(this.registration);
        }
      }
    }
  }
};
</script>
