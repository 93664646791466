<template>
  <div class="m-card">
    <div class="row no-gutters p-3">
      <div class="col-xl-12">
        <h1>
          <strong>{{ $t(labelRadix+".title") }}</strong>
        </h1>
        <p>{{ $t(labelRadix+".message") }}</p>
        <div class="m-card__text">
          <p class="border-bottom m-card__text--small">
            <strong>
              {{ $t(labelRadix+".subtitle_0") }}
            </strong>
          </p>

          <div class="m-card--info">
            <p>{{ $t(labelRadix+".username") }}:</p>
            <p>
              <strong>{{ userData.username }}</strong>
            </p>
          </div>
          <div class="m-card--info" >
            <p>{{ $t(labelRadix+".email") }}:</p>
            <p>
              <strong>{{ userData.email }}</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppForgotReactivateEmail",
  props: ["userData","service"],

  data() {
    return {
    };
  },
  computed: {
    labelRadix:function (){
      switch (this.service)
      {
        case "forgot":
          return "forgot";
        default:
          return "riattivaUtente";
      }
    }},

  methods: {
  },
};
</script>
